@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';









.Invoice_root {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(180deg, #14283f 0%, #050e1a 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right center;
  overflow: auto;
  align-items: center;
  justify-content: center;
  display: flex;
}
